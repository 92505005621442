import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../CollectionMoreInfo/collectionmoreinfo.scss";
import { BASE_URL } from "../../../services/baseURL";
import ModalImage from "../../ModalImage/ModalImage";

export default function CollectionMoreInfo({ collection, onClick, onReject }) {
  const [collectionData, setCollectionData] = useState(false);


  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    axios
      .get(`${BASE_URL}/api/admin/collections-await/collection/${collection._id}`,{headers})
      .then((response) => {
        setCollectionData(response.data);

      })
      .catch((error) => {
        console.error(
          "There was an error fetching the collection data!",
          error
        );
      });
  }, [collection._id]);

  const handleBackClick = () => {
    onClick();
  };
//   console.log(collectionData);
  return (
      <div>
          {
            <>
              {collectionData ?
                  <div className="nftInfo">
                    <div className="nftInfo__container">
                      <button className="nftInfo__container_back" onClick={handleBackClick}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            height="20px"
                            version="1.1"
                            viewBox="0 0 512 512"
                            width="20px"
                        >
                          <polygon
                              points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
                              style={{fill: "white"}}
                          />
                        </svg>
                        <p>Back</p>
                      </button>
                      {/*<h2>{collection?.name}</h2>*/}
                      {/*<h4>Creator: {collection?.user_address}</h4>*/}
                      <div className="nftCollection-img">
                        <ModalImage imageSrc={collection.avatar?.fileUrl} altText="" />
                        <ModalImage imageSrc={collection.banner?.fileUrl} altText="" />
                      </div>

                      <div className="nftInfo__box">
                        <div className="nftInfo__box_right">
                          <div className="nftInfo__box_right_top">
                            <h4 className="nftInfo__box_right_top_h4">Contract</h4>
                            <div className="nftInfo__box_right_top_content">
                              <p className="nftInfo__box_right_top_content_p">
                                {/*Category: {collection?.category_id ? collection?.category_id : '-'}*/}
                              </p>
                              <p className="nftInfo__box_right_top_content_p">
                                Collection: ~~~
                              </p>
                              <p className="nftInfo__box_right_top_content_p">
                                Description: {collection?.description}
                              </p>
                            </div>
                          </div>
                          <div className="nftInfo__box_right_bottom">
                            <p className="nftInfo__box_right_bottom_h4">Social networks:</p>
                            <div className="nftInfo__box_right_top_content">
                              <p className="nftInfo__box_right_top_content_p">-</p>
                              <p className="nftInfo__box_right_top_content_p">
                                Transaction Hash:
                              </p>
                              <p className="nftInfo__box_right_top_content_p">-</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> : null}
            </>
          }
      </div>
  );
}
