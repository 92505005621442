import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import "./manageActive.scss";
import AllNft from "../../../Components/ManageNFT/AllNft/AllNft";

const AllNftPage = () => {
  return (
    <div className="manage-active">
      <Sidebar />
      <div className="manage-active__container">
        <Navbar />
        <div>
          <h2 style={{ textAlign: "center", padding: "10px" }}>
            New manage NFT page
          </h2>
          <AllNft />
        </div>
      </div>
    </div>
  );
};

export default AllNftPage;
