import axios from "axios";

export const fetchAllItems = async (url) => {
    try {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        let response
        response = await axios.get(`${url}`, {
            headers: headers,
        });

        const data = response.data.data;

            

        return data;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};
