import React, { useState, useEffect } from 'react';
import './modalimage.scss'; // Убедитесь, что у вас есть этот файл стилей



import noImage from "../../Assets/images/no-image.jpg"



export const isVideo = (url) => {
    try {
        const urlObj = new URL(url);
        const videoId = urlObj.searchParams.get("v");
        return videoId ? videoId : null;
    } catch (error) {
        return null;
    }
};


const isAudioFile = (article) => {
    const isCategoryMatch = article?.category_id === "667ee42be03e65cd12d5b8c7" || article?.categoryId === "667ee42be03e65cd12d5b8c7";
    const hasTelegramId = article?.collection_id !== undefined || article?.collectionId !== undefined;
    
    return isCategoryMatch && hasTelegramId;
};

const ModalImage = ({ imageSrc, altText, nft, imgClass }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [videoSrc, setVideoSrc] = useState('');
    
    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);
    const handleImageLoad = () => setIsLoading(false);



    
    const loadVideoSrc = async () => {
        const playbackId = isVideo(imageSrc);
        if (playbackId) {
            try {
                setVideoSrc(imageSrc);
            } catch (error) {
                console.error("Error loading video source", error);
            }
        }
        setIsLoading(false);
    };
    useEffect(() => {
        loadVideoSrc();
    }, [imageSrc]);
    return (
        <div>
            {isLoading && <p>Loading...</p>}
            {videoSrc ? (
                <div className="video-container">
                    <iframe src={videoSrc} frameborder="0"></iframe>
                </div>
            ) : isAudioFile(nft) ? (
                <div className="card_audio" style={{ position: "relative" }}>
                    <img src={nft?.collectionAvatar?.previewUrl || imageSrc || noImage} alt="audio" style={{ position: "relative" }} />
                    <audio controls style={{ position: "absolute", left: "0", bottom: '0', width:"100%"}}>
                        <source src={`https://media-backend.tribeton.io${nft.image.localPath}`}/>
                        Your browser does not support the audio element.
                    </audio>
                </div>
            ) : (
                <img
                    src={imageSrc}
                    alt={altText}
                    onClick={handleOpenModal}
                    onLoad={handleImageLoad}
                    className={imgClass}
                    style={{ cursor: 'pointer', width: '200px', display: isLoading ? 'none' : 'block' }}
                />
            )}

            {isOpen && (
                <div className="modal" onClick={handleCloseModal}>
                    <span className="close">&times;</span>
                    <img className='modal-content' src={imageSrc} alt={altText} />
                </div>
            )}
        </div>
    );
};

export default ModalImage;
