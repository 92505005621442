import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import "./point.scss";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../services/baseURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Point = () => {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [statusMap, setStatusMap] = useState({});

  // Fetch data
  const fetchWithdrawalData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/point-service`);
      const data = Array.isArray(response.data)
        ? response.data
        : [response.data];
      setWithdrawalData(data);
    } catch (error) {
      console.error("Error fetching withdrawal data:", error);
      setWithdrawalData([]);
    }
  };

  useEffect(() => {
    fetchWithdrawalData();
  }, []);

  const handleInputChange = (event, id, field) => {
    const updatedData = withdrawalData.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setWithdrawalData(updatedData);
  };

  const updateWithdrawalData = async (itemId) => {
    const item = withdrawalData.find((item) => item.id === itemId);
    if (!item) return;

    try {
      const response = await axios.patch(
        `${BASE_URL}/point-service/${item.id}`,
        {
          price: item.price,
        }
      );

      if (response.status === 200) {
        toast.success("Point settings updated successfully!");
        updateStatus(itemId, "success");
      } else {
        toast.error("Unexpected response from server.");
        updateStatus(itemId, "error");
      }
    } catch (error) {
      console.error("Error updating point data:", error);
      toast.error("Failed to update point settings.");
      updateStatus(itemId, "error");
    }
  };

  const updateStatus = (itemId, status) => {
    setStatusMap((prevStatus) => ({ ...prevStatus, [itemId]: status }));
    setTimeout(() => {
      setStatusMap((prevStatus) => {
        const { [itemId]: _, ...rest } = prevStatus;
        return rest;
      });
    }, 3000);
  };

  const renderTable = () => (
    <table className="withdrawal-table">
      <thead>
        <tr>
          <th>Action</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {withdrawalData.map((item) => {
          const itemStatus = statusMap[item.id] || "default";

          return (
            <tr key={item._id}>
              <td>
                <TextField
                  value={item.name}
                  onChange={(e) => handleInputChange(e, item.id, "name")}
                  size="small"
                />
              </td>
              <td>
                <TextField
                  value={item.price}
                  onChange={(e) => handleInputChange(e, item.id, "price")}
                  size="small"
                  type="number"
                />
              </td>
              <td>
                <Button
                  variant="contained"
                  color={itemStatus === "success" ? "success" : "primary"}
                  onClick={() => updateWithdrawalData(item.id)}
                  disabled={itemStatus === "success"}
                >
                  {itemStatus === "success" ? "Saved" : "Save"}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className="withdrawalBar">
      <Sidebar />
      <div className="withdrawalContainer">
        <Navbar />
        <div className="withdrawalContent">
          <h2>Point Configuration</h2>
          <div className="withdrawalTable">{renderTable()}</div>
        </div>
      </div>
    </div>
  );
};

export default Point;
