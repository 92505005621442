import React, { useEffect, useState } from 'react';
import './changepricelike.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, TextField } from "@mui/material";
import {TonConnectButton,useTonConnectUI,useTonWallet} from '@tonconnect/ui-react'
import {Address, beginCell ,toNano} from '@ton/ton'
import { createNewPriceEntry, updateExistingLikePriceEntry, getAllLikePrice } from "../../services/likeService";

import { BASE_URL } from '../../services/baseURL';
import axios from 'axios';

const ChangePriceLike = () => {
    const [tonConnectUI] = useTonConnectUI();
    const wallet = useTonWallet();
    const [type, setType] = useState('CollectionItem');
    const [existingData, setExistingData] = useState([]);
    const [burnInputValue, setBurnInputValue] = useState('');
    const [burnDisplayValue, setBurnDisplayValue] = useState('');
    const [jettonWalletData, setJettonWalletData] = useState([]);
    const [statusMap, setStatusMap] = useState({});
    const accessToken = localStorage.getItem("token");
    const [likeBurns, setLikeBurns] = useState('');
    const jettonAddress = "EQCcXKwYGCCZnzQj9vwwg8Y-F3d8H7cow-Mwgj8pTFruBfP8";
    const fetchLikesBurn = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/likes_fond`);
            const burn = response.data[0].burnCommission;
            setLikeBurns(burn);
            console.log(burn);
        } catch (error) {
            console.error("There was an error fetching the likes!", error);
        }
    };
       
        const fetchJettonWallets = async (ownerAddress) => {
            if (!ownerAddress) {
              console.error("Owner address is undefined");
              return;
            }
            try {
              const response = await axios.get(`https://toncenter.com/api/v3/jetton/wallets?owner_address=${ownerAddress}&jetton_address=${jettonAddress}&limit=128&offset=0`);
              const jettonWallets = response.data.jetton_wallets;
              const wallets = jettonWallets.map((wallet) => ({
                address: wallet.address,
                balance: parseFloat(wallet.balance) / 1e2,
              }));
              setJettonWalletData(wallets);
            } catch (error) {
              console.error("Error fetching jetton wallets:", error);
            }
          };
    useEffect(() => {
        if (!wallet || !wallet.account?.address) {
          console.warn("Waiting for wallet connection...");
          return;
        }
        const ownerAddress = wallet.account.address;
        fetchJettonWallets(ownerAddress);
      }, [wallet]);
    useEffect(() => {
        getAllLikePrice().then(response => {
            setExistingData(response.data);
        });
        fetchLikesBurn();
    }, []);

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const handleBurnChange = (event) => {
        const input = event.target.value;
    
        // Allow empty input to support clearing the field
        if (input === '') {
            setBurnInputValue('');
            return;
        }
    
        const parsedValue = parseFloat(input);
        const maxValue = parseFloat(likeBurns);
    
        // Validate the input to ensure it is within the range [0, maxValue]
        if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= maxValue) {
            setBurnInputValue(input);
        } else if (parsedValue < 0) {
            setBurnInputValue('');
        } else if (parsedValue > maxValue) {
            setBurnInputValue(maxValue.toString());
        }
    };
    

    const updateTransaction = (wallets, callback) => {
        const inputAmountNano = burnInputValue * 100;

        if (isNaN(Number(inputAmountNano))) {
            console.error("Invalid input amount");
            return;
        }

        if (wallets.length > 0) {
            const firstWallet = wallets[0];

            const body = beginCell()
                .storeUint(0x595f07bc, 32)
                .storeUint(0, 64)
                .storeCoins(inputAmountNano)
                .storeAddress(
                    Address.parse(firstWallet.address)
                )
                .storeUint(0, 1)
                .endCell();

            const newTransaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                    {
                        address: firstWallet.address,
                        amount: toNano(0.05).toString(),
                        payload: body.toBoc().toString("base64"),
                    },
                ],
            };

            if (callback) {
                callback(newTransaction);
            }
        }
    };

    const sendBurnTransaction = (transaction) => {
        if (!transaction) {
            console.error("Transaction object is not set.");
            return;
        }
        
        tonConnectUI.sendTransaction(transaction)
            .then(async (response) => {
                const boc = response.boc;
                try {
                    await axios.put(`${BASE_URL}/likes_fond/decrease-commission`, {
                        amount: Number(burnInputValue),
                    }, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setLikeBurns(likeBurns);
                    setBurnInputValue('');
                    console.log("Burn transaction confirmed!");
                } catch (error) {
                    console.error("Error confirming burn transaction:", error);
                }
            })
            .catch((error) => {
                console.error("Transaction error:", error);
            });
    };

    const handleBurnSubmit = () => {
        updateTransaction(jettonWalletData, sendBurnTransaction);
    };

    const changePriceForLike = (itemId) => {
        const existingItem = existingData.find(item => item._id === itemId);

        if (!existingItem) return;

        const data = {
            "itemType": existingItem.itemType,
            "price": +existingItem.price,
            "ownerFee": +existingItem.ownerFee,
            "bern": +existingItem.bern,
            "userLevel": existingItem.userLevel,
            "itemLevel": existingItem.itemLevel
        };

        updateExistingLikePriceEntry(data, existingItem._id)
            .then((data) => {
                if (!data.error) {
                    updateStatus(itemId, 'success');
                } else {
                    updateStatus(itemId, 'error');
                }
            })
            .catch(() => {
                updateStatus(itemId, 'error');
            });
    };

    const updateStatus = (itemId, newStatus) => {
        setStatusMap((prevState) => ({
            ...prevState,
            [itemId]: newStatus
        }));
    };

    const renderTable = () => {
        const filteredData = existingData.filter(item => item.itemType === type);
        return (
            <table className="price-table">
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>Price</th>
                        <th>Owner Fee</th>
                        <th>Burn</th>
                        <th>Fund</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => {
                        const fund = item.price - (+item.ownerFee + (+item.bern));
                        const itemStatus = statusMap[item._id] || 'default';

                        return (
                            <tr key={item._id}>
                                <td>{item.itemLevel}</td>
                                <td>
                                    <TextField
                                        value={item.price}
                                        onChange={(e) => handleTableInputChange(e, item._id, 'price')}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        value={item.ownerFee}
                                        onChange={(e) => handleTableInputChange(e, item._id, 'ownerFee')}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        value={item.bern}
                                        onChange={(e) => handleTableInputChange(e, item._id, 'bern')}
                                    />
                                </td>
                                <td>{fund}</td>
                                <td>
                                    <Button
                                        variant="contained"
                                        color={itemStatus === 'success' ? 'success' : 'primary'}
                                        onClick={() => changePriceForLike(item._id)}
                                    >
                                        Save
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const handleTableInputChange = (event, id, field) => {
        const updatedData = existingData.map(item => {
            if (item._id === id) {
                return { ...item, [field]: event.target.value };
            }
            return item;
        });
        setExistingData(updatedData);
    };

    return (
        <div className='changePriceBar'>
            <Sidebar />
            <div className="changePriceContainer">
                <Navbar />
                <div className="changePriceLike">
                    <TonConnectButton />
                    <div className="burn-section">
                        <h2>Burn Settings</h2>
                        <TextField
                            label="Burn Input"
                            variant="outlined"
                            value={burnInputValue}
                            onChange={handleBurnChange}
                            className="burn-input"
                        />
                        <TextField
                            label="Burn Display"
                            variant="outlined"
                            value={likeBurns}
                            InputProps={{ readOnly: true }}
                            className="burn-display"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleBurnSubmit}
                        >
                            Set Burn
                        </Button>
                    </div>
                    <div className="changePriceLike_select">
                        <Box sx={{ width: 300 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Type"
                                    onChange={handleChangeType}
                                >
                                    <MenuItem value={'NFTCollection'}>NFT Collection</MenuItem>
                                    <MenuItem value={'CollectionItem'}>Collection item</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="changePriceLike_table">
                        {renderTable()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePriceLike;
