import axios from "axios";
import { BASE_URL } from "./baseURL";
import { toast } from "react-toastify";
const getHeaders = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

const apiClient = axios.create({
  baseURL: BASE_URL, // Укажите ваш базовый URL
});

// Интерцептор запросов для добавления токена
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Интерцептор ответов для обработки ошибок
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { statusCode } = error.response;

      if (statusCode === 401 || statusCode === 403) {
        console.log("Error intercepted:", error.response.data); // Логируем для отладки
        localStorage.removeItem("token");
        window.location.href = "/login";
        toast.error("Session expired. Please log in again.");
      } else {
        // Обработка других ошибок
        toast.error(
          error.response.data?.message || "An unexpected error occurred"
        );
      }
    } else {
      // Обработка сетевых ошибок
      console.error("Network or server error:", error);
      toast.error("Network error. Please try again later.");
    }

    return Promise.reject(error); // Пробрасываем ошибку дальше
  }
);

export const getBlogs = async () => {
  try {
    const response = await apiClient.get("/api/blogs");
    return response.data;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    return [];
  }
};

export const createBlogPost = async (data) => {
  try {
    const response = await apiClient.post("/api/blog", data);
    return response.data;
  } catch (error) {
    console.error("Error creating blog post:", error.message);
  }
};

export const getDisableCollectionItems = async () => {
  try {
    const response = await apiClient.get(`/api/disable/collection_items`);
    return response.data;
  } catch (error) {
    console.log(error.message);
    console.error("Error fetching disabled collection items:", error.message);
  }
};

export const getCollectionItems = async (page = 1) => {
  try {
    const response = await apiClient.get(
      `/api/collection/collection_items?page=${page}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching collection items:", error.message);
  }
};

export const getCollectionItemById = async (id) => {
  try {
    const response = await apiClient.get(
      `/api/collection/collection_items/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching collection item by ID:", error.message);
  }
};

export const getCollectionById = async (id) => {
  try {
    const response = await apiClient.get(`/api/collection/${id}`, {});
    return response.data;
  } catch (error) {
    console.error("Error fetching collection by ID:", error);
    return [];
  }
};

export const DisableCollectionItemById = async (id, body) => {
  try {
    const response = await apiClient.patch(
      `/api/visibility/collection_item/${id}`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error disabling collection item by ID:", error.message);
  }
};

export const DisableCollectionById = async (id, body) => {
  try {
    const response = await apiClient.patch(
      `/api/visibility/collection/${id}`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error disabling collection by ID:", error.message);
  }
};

export const DisableNftItemById = async (id, body) => {
  try {
    const response = await apiClient.patch(`/api/visibility/nft/${id}`, body);
    return response.data;
  } catch (error) {
    console.error("Error disabling NFT item by ID:", error.message);
  }
};

export const getModerationCollectionItem = async (id) => {
  try {
    console.log("qqqq");
    const response = await apiClient.get(
      `/api/moderation/collection-item/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching moderation collection item:", error.message);
  }
};

export const getModerationNFT = async (id) => {
  try {
    const response = await apiClient.get(`/api/moderation/nft/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching moderation NFT:", error.message);
  }
};

export const approveNFTWithoutCollection = async (nftId, status) => {
  try {
    const response = await apiClient.post(
      `/api/moderation/nft/${nftId}`,
      status
    );
    return response.data;
  } catch (error) {
    console.error("Error approving NFT without collection:", error);
  }
};

export const approveNFTWithCollection = async (nftId, status) => {
  try {
    const response = await apiClient.post(
      `/api/moderation/collection-item/${nftId}`,
      status
    );
    return response.data;
  } catch (error) {
    console.error("Error approving NFT with collection:", error);
  }
};

export const rejectNFTWithoutCollection = async (nftId, status) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/moderation/reject/nft/${nftId}`,
      { headers: getHeaders(), data: status }
    );
    return response.data;
  } catch (error) {
    console.error("Error rejecting NFT without collection:", error);
  }
};

export const rejectNFTWithCollection = async (nftId, status) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/moderation/reject/collection-item/${nftId}`,
      { headers: getHeaders(), data: status }
    );
    return response.data;
  } catch (error) {
    console.error("Error rejecting NFT with collection:", error);
  }
};

export const getModerationCollection = async (id) => {
  try {
    const response = await apiClient.get(`/api/moderation/collection/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching moderation collection by ID:", error.message);
  }
};

export const approveCollectionReq = async (collectionId, status) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/moderation/collection/${collectionId}`,
      status,
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Error approving collection:", error);
  }
};

export const RejectCollectionReq = async (collectionId) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/moderation/reject/collection/${collectionId}?status=reject`,
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Error rejecting collection:", error);
  }
};

export const ChangeCollectionPropertyById = async (collectionId, data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/moderation/collection/${collectionId}`,
      data,
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating collection property:", error);
  }
};

export const deleteBlogById = async (blogId) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/api/delete/blog/${blogId}`,
      { headers: getHeaders() }
    );
    toast.success("Blog is deleted");
    return response.data;
  } catch (error) {
    toast.error("Blog is not deleted");
    console.error("Error deleting blog:", error);
  }
};
