import React, { useEffect, useState } from 'react';
import './userboost.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../services/baseURL';

const UserBoost = () => {
  const [boostData, setBoostData] = useState([]);
  const [statusMap, setStatusMap] = useState({});

  const fetchBoostData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/boost-price`);
      setBoostData(response.data);
    } catch (error) {
      console.error('Error fetching boost data:', error);
    }
  };

  useEffect(() => {
    fetchBoostData();
  }, []);

  const handleInputChange = (event, id, field) => {
    const updatedData = boostData.map(item => {
      if (item._id === id) {
        return { ...item, [field]: event.target.value };
      }
      return item;
    });
    setBoostData(updatedData);
  };

  const updateBoostData = async (itemId) => {
    const item = boostData.find(item => item._id === itemId);
    if (!item) return;
    try {
      await axios.patch(`${BASE_URL}/boost-price/${itemId}`, {
        userLevel: item.userLevel,
        price: item.price,
        countSteps: item.countSteps,
      });
      updateStatus(itemId, 'success');
    } catch (error) {
      console.error('Error updating boost data:', error);
      updateStatus(itemId, 'error');
    }
  };

  const updateStatus = (itemId, status) => {
    setStatusMap(prevStatus => ({ ...prevStatus, [itemId]: status }));
    setTimeout(() => {
      setStatusMap(prevStatus => {
        const { [itemId]: _, ...rest } = prevStatus;
        return rest;
      });
    }, 3000);
  };

  const renderTable = () => (
    <table className="price-table">
      <thead>
        <tr>
          <th>User Level</th>
          <th>Price</th>
          <th>Count Steps</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {boostData.map((item) => {
          const itemStatus = statusMap[item._id] || 'default';

          return (
            <tr key={item._id}>
              <td>
                <TextField
                  value={item.userLevel}
                  onChange={(e) => handleInputChange(e, item._id, 'userLevel')}
                  size="small"
                  type="number"
                />
              </td>
              <td>
                <TextField
                  value={item.price}
                  onChange={(e) => handleInputChange(e, item._id, 'price')}
                  size="small"
                  type="number"
                />
              </td>
              <td>
                <TextField
                  value={item.countSteps}
                  onChange={(e) => handleInputChange(e, item._id, 'countSteps')}
                  size="small"
                  type="number"
                />
              </td>
              <td>
                <Button
                  variant="contained"
                  color={itemStatus === 'success' ? 'success' : 'primary'}
                  onClick={() => updateBoostData(item._id)}
                  disabled={itemStatus === 'success'}
                >
                  {itemStatus === 'success' ? 'Saved' : 'Save'}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className="changePriceBar">
      <Sidebar />
      <div className="changePriceContainer">
        <Navbar />
        <div className="changePriceLike">
          <h2>Boost Configuration</h2>
          <div className="changePriceLike_table">{renderTable()}</div>
        </div>
      </div>
    </div>
  );
};

export default UserBoost;
