import React, { useEffect, useState, useCallback } from "react";
import "./collection.scss";
import axios from "axios";
import CollectionMoreInfo from "../CollectionMoreInfo/CollectionMoreInfo";
import { BASE_URL } from "../../services/baseURL";

const fetchAllItems = async (url) => {
  try {
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    throw error;
  }
};

const Collection = () => {
  const [nftList, setNftList] = useState([]);
  const [isCollectionItemClicked, setIsCollectionItemClicked] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collections = await fetchAllItems(
          `${BASE_URL}/api/moderation/collections`
        );
        setNftList(collections);
      } catch (error) {
        console.error("There was an error fetching the collections!", error);
        setNftList([]);
      }
    };

    fetchData();
  }, []);

  const handleNftItemClick = useCallback((collection) => {
    setSelectedCollection(collection);
    setIsCollectionItemClicked(true);
  }, []);

  const handleNftMoreInfoFalse = useCallback(() => {
    setIsCollectionItemClicked(false);
  }, []);

  const handleReject = useCallback((collectionId) => {
    setNftList((prevNftList) =>
      prevNftList.filter((collection) => collection._id !== collectionId)
    );
  }, []);

  return (
    <>
      {isCollectionItemClicked && selectedCollection ? (
        <CollectionMoreInfo
          collection={selectedCollection}
          onClick={handleNftMoreInfoFalse}
          onReject={handleReject}
        />
      ) : (
        <div className="collection">
          <div className="collection__container">
            <h1>Collection</h1>
            {nftList.map((collection, index) => (
              <div className="collection__item" key={index}>
                <h3>Collection name</h3>
                <h4 className="collection__item_name">
                  {collection.name.length > 15
                    ? `${collection.name.substring(0, 15)}...`
                    : collection.name}
                </h4>
                <button onClick={() => handleNftItemClick(collection)}>
                  More
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Collection;
