import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Box,
  Divider
} from '@mui/material';
import './addreward.scss'
import { BASE_URL } from '../../services/baseURL';

function AddRewardModal({ open, onClose, onSave }) {
  const [newReward, setNewReward] = useState({
    name: '',
    description: '',
    acceptNftLevel: 0,
    startDate: '',
    endDate: '',
    daysCount: '',
    isActive: false,
    // banner: null,
    // base_apr:'',
    coefficients: {
      nftLevel: 0,
      nftLikesCount: 0,
      userBoostsCount: 0
    }
  });
  const [bannerPreview, setBannerPreview] = useState(null);
  const [dayCount, setDayCount] = useState(0);

  const formatDateForInput = (date) => {
    return date ? new Date(date).toISOString().slice(0, 16) : '';
  };

  const handleChange = (field, value) => {
    setNewReward(prev => ({ ...prev, [field]: value }));
  };

  const handleNestedChange = (field, nestedField, value) => {
    setNewReward(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        [nestedField]: value
      }
    }));
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewReward(prev => ({ ...prev, banner: file }));
      setBannerPreview(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    if (newReward.startDate && newReward.endDate) {
      const start = new Date(newReward.startDate);
      const end = new Date(newReward.endDate);
      const diffInMs = end - start;
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      setDayCount(days);
      setNewReward(prev => ({ ...prev, daysCount: days })); 
    } else {
      setDayCount(0);
      setNewReward(prev => ({ ...prev, daysCount: '' })); 
    }
  }, [newReward.startDate, newReward.endDate]);

  const handleSave = async () => {
    const formattedReward = {
      ...newReward,
      startDate: new Date(newReward.startDate).toISOString(),
      endDate: new Date(newReward.endDate).toISOString(),
      acceptNftLevel: Number(newReward.acceptNftLevel),
      daysCount: Number(newReward.daysCount),
      isActive: Boolean(newReward.isActive),
      coefficients: {
        nftLevel: Number(newReward.coefficients.nftLevel),
        nftLikesCount: Number(newReward.coefficients.nftLikesCount),
        userBoostsCount: Number(newReward.coefficients.userBoostsCount),
      },
    };
  
    try {
      const response = await fetch(`${BASE_URL}/rewards_season`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Устанавливаем правильный заголовок
        },
        body: JSON.stringify(formattedReward), // Отправляем данные в формате JSON
      });
  
      console.log(formattedReward); // Для проверки отправляемых данных
  
      if (response.ok) {
        // onSave();
        onClose();
      } else {
        console.error("Failed to add new reward:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding new reward:", error);
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ borderRadius: 3, boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)' }}>
      <DialogTitle sx={{ backgroundColor: '#1e3a8a', color: '#fff', fontWeight: 'bold', textAlign: 'center', py: 2 }}>
        Add New Reward
      </DialogTitle>
      <DialogContent sx={{ py: 4, px: 3, backgroundColor: '#f7f9fb' }}>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          variant="outlined"
          value={newReward.name}
          onChange={(e) => handleChange('name', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Description"
          fullWidth
          variant="outlined"
          value={newReward.description}
          onChange={(e) => handleChange('description', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Accept NFT Level"
          type="number"
          fullWidth
          variant="outlined"
          value={newReward.acceptNftLevel}
          onChange={(e) => handleChange('acceptNftLevel', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Start Date"
          type="datetime-local"
          fullWidth
          variant="outlined"
          value={newReward.startDate}
          onChange={(e) => handleChange('startDate', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="End Date"
          type="datetime-local"
          fullWidth
          variant="outlined"
          value={newReward.endDate}
          onChange={(e) => handleChange('endDate', e.target.value)}
          sx={{ mb: 2 }}
        />

        <Typography variant="body2" sx={{ mt: 2, color: '#1e3a8a' }} value={newReward.days} onChange={(e) => handleChange('daysCount', Number(e.target.value))}>
          Duration: {dayCount} {dayCount === 1 ? 'day' : 'days'}
        </Typography>

        <Divider sx={{ my: 3, borderColor: '#1e3a8a', borderWidth: '1px' }} />

        {/* <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1e3a8a', mb: 1 }}>
          Upload Banner
        </Typography>
        <input type="file" accept="image/*" onChange={handleBannerChange} />
        {bannerPreview && (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <img src={bannerPreview} alt="Banner Preview" style={{ width: '90%', maxHeight: '240px', borderRadius: '8px' }} />
          </Box>
        )} */}

        <FormControlLabel
          control={
            <Switch
              checked={newReward.isActive}
              onChange={(e) => handleChange('isActive', e.target.checked)}
            />
          }
          label="Is Active"
          sx={{ my: 2, color: '#1e3a8a' }}
        />
        
        <Divider sx={{ my: 3, borderColor: '#1e3a8a', borderWidth: '1px' }} />
        
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1e3a8a', mb: 1 }}>
          Coefficients
        </Typography>
        <TextField
        margin="normal"
        label="NFT Level"
        type="number"
        fullWidth
        variant="outlined"
        value={newReward.coefficients.nftLevel}
        onChange={(e) => handleNestedChange('coefficients', 'nftLevel', Number(e.target.value))}
        inputProps={{ min: 12 }}
        sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="NFT Likes Count"
          type="number"
          fullWidth
          variant="outlined"
          value={newReward.coefficients.nftLikesCount}
          onChange={(e) => handleNestedChange('coefficients', 'nftLikesCount', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="User Boosts Count"
          type="number"
          fullWidth
          variant="outlined"
          value={newReward.coefficients.userBoostsCount}
          onChange={(e) => handleNestedChange('coefficients', 'userBoostsCount', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
         {/* <TextField
          margin="normal"
          label="Base APR"
          type="number"
          fullWidth
          variant="outlined"
          value={newReward.base_apr}
          onChange={(e) => handleNestedChange('coefficients', 'baseAPR', Number(e.target.value))}
          sx={{ mb: 2 }}
        /> */}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', backgroundColor: '#f1f1f1', py: 2 }}>
        <Button onClick={onClose} color="secondary" variant="outlined" sx={{ mr: 2, borderColor: '#1e3a8a', color: '#1e3a8a' }}>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" sx={{ backgroundColor: '#1e3a8a', '&:hover': { backgroundColor: '#16336e' } }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddRewardModal;
