import React, { useEffect, useState } from 'react';
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import { Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EditRewardModal from '../../Components/EditRewardModal/EditRewardModal';
import AddRewardModal from '../../Components/AddRewardModal/AddRewardModal'; // Import the AddRewardModal component
import './rewards.scss';
import { BASE_URL } from '../../services/baseURL';

function Rewards() {
  const [rewardsData, setRewardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false); // State to control AddRewardModal
  const [selectedReward, setSelectedReward] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${BASE_URL}/rewards_season/all_seasons`);
        const data = await response.json();
        setRewardsData(data);
      } catch (error) {
        console.error('Error fetching rewards data:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const handleEditClick = (reward) => {
    setSelectedReward(reward);
    setOpenEditModal(true);
  };

  const handleAddClick = () => {
    setOpenAddModal(true); // Open the AddRewardModal
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
    setSelectedReward(null);
  };

  const handleAddModalClose = () => {
    setOpenAddModal(false);
  };

  const handleInputChange = (field, value) => {
    setSelectedReward({ ...selectedReward, [field]: value });
  };

  const handleNestedChange = (parentField, field, value) => {
    setSelectedReward({
      ...selectedReward,
      [parentField]: {
        ...selectedReward[parentField],
        [field]: value
      }
    });
  };

  const handleSaveChanges = () => {
    // Add API call to save changes to the server
    console.log("Updated Reward Data:", selectedReward);
    handleEditModalClose();
  };

  const handleSaveNewReward = async (newReward) => {
    try {
      const response = await fetch(`${BASE_URL}/rewards_season`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newReward),
      });

      if (response.ok) {
        const savedReward = await response.json();
        setRewardsData((prevRewards) => [...prevRewards, savedReward]); // Update rewards data with the new reward
        handleAddModalClose(); // Close the modal
      } else {
        console.error('Failed to add new reward:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding new reward:', error);
    }
  };

  return (
    <div className="rewards">
      <Sidebar />
      <div className="rewards__container">
        <Navbar />
        <Box sx={{ padding: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h5" component="h1" fontWeight="bold" sx={{ color: '#003366' }}>
              Rewards
            </Typography>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
              Add New
            </Button>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} sx={{ borderRadius: 3, boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#f4f6f8' }}>
                    <TableCell>Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Total Rewards</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rewardsData.map((reward) => (
                    <TableRow key={reward.id} hover sx={{ '&:hover': { backgroundColor: '#f0f8ff' } }}>
                      <TableCell>{reward.name}</TableCell>
                      <TableCell>{new Date(reward.startDate).toLocaleDateString()}</TableCell>
                      <TableCell>{reward.totalRewardsAmount}</TableCell>
                      <TableCell align="center">
                        <IconButton color="primary" onClick={() => handleEditClick(reward)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <EditRewardModal
          open={openEditModal}
          reward={selectedReward}
          onClose={handleEditModalClose}
          onSave={handleSaveChanges}
          onChange={handleInputChange}
          onNestedChange={handleNestedChange}
        />
        <AddRewardModal
          open={openAddModal}
          onClose={handleAddModalClose}
          onSave={handleSaveNewReward}
        />
      </div>
    </div>
  );
}

export default Rewards;
