import React, { PureComponent } from 'react';
import { PieChart, Pie,  Cell, ResponsiveContainer } from 'recharts';
import "./piecharts.scss"


const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export class PieCharts extends PureComponent {
 
  render() {
    
    return (
      
      <div className='pieChart'>
        <span>Login By Browser (Last 30 days)</span>
        <ResponsiveContainer className='pieChart' width="100%" height={400}>
        <PieChart width={360} height={400} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data}
          
          innerRadius={40}
          outerRadius={150}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        
      </PieChart>
      </ResponsiveContainer>
      </div>
    )
  }
}

export default PieCharts

