import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Stack,
  TextField,
  Typography,
  Modal,
  Switch,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../services/baseURL";

const statusColors = {
  "awaiting publication": "#FFC107", // Yellow
  published: "#4CAF50", // Green
};

const isEnabledColors = {
  true: "#4CAF50", // Green
  false: "#F44336", // Red
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function AllNft() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(`${BASE_URL}/api/collection_items`);
        const processedData = resp.data.data.map((item) => ({
          ...item,
          id: item._id, // Используем _id как id для DataGrid
        }));
        setData(processedData);
        setFilteredData(processedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFilter = (status, isEnabled = null) => {
    setActiveFilter(status);
    const filtered =
      status === "all" ? data : data.filter((item) => item.status === status);

    const enabledFiltered =
      isEnabled === null
        ? filtered
        : filtered.filter((item) => item.isEnabled === isEnabled);

    const searchedData = enabledFiltered.filter(
      (item) =>
        item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(searchedData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filtered =
      activeFilter === "all"
        ? data
        : data.filter((item) => item.status === activeFilter);

    const searchedData = filtered.filter(
      (item) =>
        item._id.toLowerCase().includes(query.toLowerCase()) ||
        item.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(searchedData);
  };

  const handleSwitchChange = async (e) => {
    const newValue = e.target.checked;

    try {
      const token = localStorage.getItem("token"); // Получение токена из localStorage

      await axios.patch(
        `${BASE_URL}/api/visibility/collection_item/${selectedRow.id}`,
        {
          isEnabled: newValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Добавляем токен в заголовок
          },
        }
      );

      setSelectedRow((prev) => ({ ...prev, isEnabled: newValue }));

      // Обновляем данные в таблице
      setData((prevData) =>
        prevData.map((item) =>
          item.id === selectedRow.id ? { ...item, isEnabled: newValue } : item
        )
      );
      setFilteredData((prevData) =>
        prevData.map((item) =>
          item.id === selectedRow.id ? { ...item, isEnabled: newValue } : item
        )
      );
    } catch (error) {
      console.error("Error updating isEnabled:", error);
    }
  };

  const columns = [
    { field: "_id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "categoryTitle", headerName: "Category", width: 150 },
    { field: "likes", headerName: "Likes", type: "number", width: 100 },
    { field: "level", headerName: "Level", type: "number", width: 100 },
    { field: "price", headerName: "Price Tribe", type: "number", width: 100 },
    { field: "tonPrice", headerName: "Price TON", type: "number", width: 100 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: statusColors[params.row.status] || "#757575", // Default Gray
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {params.row.status || "Unknown"}
        </span>
      ),
    },
    {
      field: "isEnabled",
      headerName: "Is Enabled",
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: "white",
            backgroundColor: isEnabledColors[params.row.isEnabled] || "#757575", // Default Gray
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {params.row.isEnabled ? "Yes" : "No"}
        </span>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,
      renderCell: (params) =>
        params.row?.image?.previewUrl ? (
          <img
            src={params.row.image.previewUrl}
            alt={params.row.name}
            style={{ width: "100%", height: "auto", cursor: "pointer" }}
            onClick={() => handleOpen(params.row)}
          />
        ) : (
          "No Image"
        ),
    },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
        <Button
          variant={activeFilter === "all" ? "contained" : "outlined"}
          onClick={() => handleFilter("all")}
        >
          ALL
        </Button>
        <Button
          variant={
            activeFilter === "awaiting publication" ? "contained" : "outlined"
          }
          onClick={() => handleFilter("awaiting publication")}
        >
          Awaiting Published
        </Button>
        <Button
          variant={activeFilter === "published" ? "contained" : "outlined"}
          onClick={() => handleFilter("published")}
        >
          Published
        </Button>
        <Button
          variant={activeFilter === "isEnabled" ? "contained" : "outlined"}
          onClick={() => handleFilter("all", true)}
        >
          Is Enabled
        </Button>
        <Button
          variant={activeFilter === "notEnabled" ? "contained" : "outlined"}
          onClick={() => handleFilter("all", false)}
        >
          Not Enabled
        </Button>
      </Stack>
      <TextField
        label="Search by ID or Name"
        variant="outlined"
        fullWidth
        sx={{ marginBottom: 2 }}
        onChange={handleSearch}
        value={searchQuery}
      />
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 23,
              },
            },
          }}
          pageSizeOptions={[10, 23, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          {selectedRow && (
            <>
              <Typography variant="h6" component="h2">
                {selectedRow.name}
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <strong>Category:</strong> {selectedRow.categoryTitle}
              </Typography>
              <Typography>
                <strong>Status:</strong> {selectedRow.status}
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <strong>Is Enabled:</strong>
                <Switch
                  checked={selectedRow.isEnabled}
                  onChange={handleSwitchChange}
                />
              </Typography>
              <Typography>
                <strong>Description:</strong>{" "}
                {selectedRow.description || "No description"}
              </Typography>

              {/* Обработка различных типов файлов */}
              {selectedRow.image?.fileType === "image" &&
                selectedRow.image.previewUrl && (
                  <img
                    src={selectedRow.image.previewUrl}
                    alt={selectedRow.name}
                    style={{ width: "100%", marginTop: "10px" }}
                  />
                )}
              {selectedRow.image?.fileType === "audio" && (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                  {selectedRow.image.previewUrl && (
                    <img
                      src={selectedRow.image.previewUrl}
                      alt="Audio Thumbnail"
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "cover",
                        marginBottom: "10px",
                      }}
                    />
                  )}
                  {selectedRow.image.localPath && (
                    <audio controls style={{ width: "100%" }}>
                      <source
                        src={`https://media-backend.tribeton.io${selectedRow.image.localPath}`}
                        type="audio/mpeg"
                      />
                      Ваш браузер не поддерживает аудиоэлемент.
                    </audio>
                  )}
                </div>
              )}
              {selectedRow.image?.fileType === "video" &&
                selectedRow.image.fileUrl && (
                  <iframe
                    src={selectedRow.image.fileUrl}
                    title={selectedRow.name}
                    style={{
                      width: "100%",
                      height: "400px",
                      marginTop: "10px",
                      border: "none",
                    }}
                    allowFullScreen
                  ></iframe>
                )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
