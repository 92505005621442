import React, { useState, useEffect } from 'react';
import '../../Components/NFTItem/nftitem.scss'
import { Livepeer } from "livepeer";
import { getSrc } from "@livepeer/react/external";

import noImage from "../../Assets/images/no-image.jpg"

const livepeer = new Livepeer({
    apiKey: "07fea5de-2f43-40f9-aac5-f654b8e7d1f3",
  });
  
  const getPlaybackSource = async (playbackId) => {
    try {
      const playbackInfo = await livepeer.playback.get(playbackId);
      return getSrc(playbackInfo.playbackInfo);
    } catch (error) {
      console.error(
        `Error fetching playback source for ID ${playbackId}:`,
        error
      );
      throw error;
    }
  };
  

const ModalListNFT = ({ imageSrc, altText , nft , onClick}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [videoSrc, setVideoSrc] = useState('');
    const [collections, setCollections] = useState([]);

    const handleItemClick = () => {
        onClick(nft);
    };
    const isVideo = (url) => {
        const urlObj = new URL(url);
        const videoId = urlObj.searchParams.get('v');
        return videoId ? videoId : null;
    };

    // const fetchCollections = async () => {
    //     try {
    //         const response = await axios.get(`${BASE_URL}/api/collections`);
    //         const newCollections = response.data.data;
    //         setCollections(newCollections);
    //     } catch (error) {
    //         console.error("There was an error fetching the collections!", error);
    //     }
    // };

    // useEffect(() => {
    //     fetchCollections();
    // }, []);

    const isAudioFile = (article) => {
        const isCategoryMatch = article.category_id === "667ee42be03e65cd12d5b8c7" || article.categoryId === "667ee42be03e65cd12d5b8c7";
        const hasTelegramId = article.collection_id !== undefined || article.collectionId !== undefined;
        
        return isCategoryMatch && hasTelegramId;
    };
  

    useEffect(() => {
        const loadVideoSrc = async () => {
            const playbackId = isVideo(imageSrc);
            if (playbackId) {
                const src = await getPlaybackSource(playbackId);
                setVideoSrc(src);
            }
            setIsLoading(false);
        };

        loadVideoSrc();
    }, [imageSrc]);
    console.log(nft);
    
    const playbackId = isVideo(imageSrc);
    return (
        <div>
            {isLoading && <p>Loading...</p>}
            {playbackId && videoSrc ? (
                <div className="divdidvi"
                onClick={handleItemClick} style={{ cursor:'pointer'}}>
                   <img
                    src={imageSrc}
                    alt={altText}
                    onError={(e) => { e.target.src = noImage; }}
                    // style={{ cursor: 'pointer', width: '200px', display: isLoading ? 'none' : 'block' }}
                />
                </div>
            ) : isAudioFile(nft) ? (
                <div className="card_audio" style={{ position: "relative"}}>
                    <img src={nft?.collectionAvatar?.previewUrl || noImage} alt="audio" style={{ position: "relative"}} onClick={handleItemClick}/>

                </div>
            ) : (
                <img
                onClick={handleItemClick}
                    src={imageSrc}
                    alt={altText}
                    onError={(e) => { e.target.src = noImage; }}
                    // style={{ cursor: 'pointer', width: '200px', display: isLoading ? 'none' : 'block' }}
                />
            )}
        </div>
    );
};

export default ModalListNFT;
