import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./collectionmoreinfo.scss";
import ModalImage from "../ModalImage/ModalImage";
import {
    approveCollectionReq,
    getModerationCollection,
    RejectCollectionReq
} from "../../services/serverRequest";
import LevelChange from "../LevelChange/LevelChange";

export default function CollectionMoreInfo({ collection, onClick, onReject }) {
  const [collectionData, setCollectionData] = useState(false);
  const [lvl, setLvl] = useState(collection?.level);


  useEffect(() => {
    getModerationCollection(collection._id)
        .then((response) => {
          console.log(response);
          
          setCollectionData(response);
        })
        .catch((error) => {
          console.error(
              "There was an error fetching the collection data!",
              error
          );
        });
  }, [collection._id]);

  const handleBackClick = () => {
    onClick();
  };
  const approveCollection = () => {
      if (isNaN(+lvl) && +lvl > 0 ) {
          alert("Level must be a number greater than 0");
          return;
      }
      else{
          console.log('yes!')
          approveCollectionReq(collection._id, { status: "approved",level: lvl })
              .then((response) => {
                  toast.success("Successfully approved collection");

                  onReject(collection._id);
                   handleBackClick();
              })
              .catch((error) => {
                  console.error("There was an error approving the collection!", error);
              });
      }

  };

  const rejectCollection = () => {

    RejectCollectionReq(collection._id)
        .then((response) => {
              toast.error("Successfully rejected collection");
              onReject(collection._id);
              handleBackClick();
        })
        .catch((error) => {
          console.error("There was an error rejecting the collection!", error);
        });

  };

console.log(collectionData);

  return (
      <div>
          {
            <>
              {collectionData ?
                  <div className="nftInfo">
                    <div className="nftInfo__container">
                      <button className="nftInfo__container_back" onClick={handleBackClick}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            height="20px"
                            version="1.1"
                            viewBox="0 0 512 512"
                            width="20px"
                        >
                          <polygon
                              points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
                              style={{fill: "white"}}
                          />
                        </svg>
                        <p>Back</p>
                      </button>
                      {/*<h2>{collection?.name}</h2>*/}
                      {/*<h4>Creator: {collection?.user_address}</h4>*/}
                      <div className="nftCollection-img">
                        <ModalImage imageSrc={collection.avatar?.fileUrl} altText="" />
                        <ModalImage imageSrc={collection.banner?.fileUrl} altText="" />
                      </div>

                      <div className="nftInfo__box">
                        <div className="nftInfo__box_right">
                          <div className="nftInfo__box_right_top">
                            <h4 className="nftInfo__box_right_top_h4">Contract</h4>
                              <div className="nftInfo__box_right_top_content">
                                  <p className="nftInfo__box_right_top_content_p">
                                  </p>
                                  <p className="nftInfo__box_right_top_content_p">
                                      Collection: {collection.name || '~~'}
                                  </p>
                                  <p className="nftInfo__box_right_top_content_p">
                                      Description: {collection?.description || '~~'}
                                  </p>
                                  <p className="nftInfo__box_right_top_content_p">
                                      <LevelChange lvl={lvl} setLevel={setLvl}/>
                                  </p>
                              </div>
                          </div>
                            <div className="nftInfo__box_right_bottom">
                                <p className="nftInfo__box_right_bottom_h4">Social networks: <a
                              target='_blank'
                              rel="noreferrer"
                              href={`https://t.me/${collection.telegram}`}>{collection.telegram ? collection.telegram : "~~"}</a></p>
                              <div className="nftInfo__box_right_top_content">
                                  <p className="nftInfo__box_right_top_content_p">
                                    Category: {collection.categoryTitle || '~~'}
                                  </p>
                              </div>
                          </div>
                        </div>
                      </div>
                        <div className="nftInfo__btns">
                            <button onClick={approveCollection} className="nftInfo__greenBtn">
                              Approve
                            </button>
                            <button onClick={rejectCollection} className="nftInfo__redBtn">
                              Refuse
                            </button>
                      </div>
                    </div>
                  </div> : <p></p>}
            </>
          }
      </div>
  );
}
