import * as React from "react";
import { useEffect, useState } from "react";
import { getCollectionById } from "../../../services/serverRequest";
import "../../NftMoreInfo/nftmoreinfo.scss";
import axios from "axios";
import { BASE_URL } from "../../../services/baseURL";

import ModalImage from "../../ModalImage/ModalImage";


export default function NftAwaitMore({ nft, onClick, onReject }) {
  const [nftData, setNftData] = useState([]);
  const [collection, setCollection] = useState([]);
  useEffect(() => {
    const fetchCollection = async () => {
        if (nft.collection_id) {
            const fetchedCollection = await getCollectionById(nft.collection_id);
            setCollection(fetchedCollection);
        }
    };
    fetchCollection();
}, [nft.collection_id]);
  useEffect(() => {
    if (nft.collection_id) {
      axios
        .get(`${BASE_URL}/api/admin/collection_items-await/collection_items/${nft._id}`)
        .then((data) => {
          setNftData(data);
        })
        .catch((error) => {});
    } else {
      axios
        .get(`${BASE_URL}/api/admin/nfts-await/nft/${nft._id}`)
        .then((data) => {
          setNftData(data);
        })
        .catch((error) => {});
    }
  }, [nft._id]);

  const handleBackClick = () => {
    onClick();
  };

  return (
    <div className="nftInfo">
      <div className="nftInfo__container">
        <button className="nftInfo__container_back" onClick={handleBackClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height="20px"
            version="1.1"
            viewBox="0 0 512 512"
            width="20px"
          >
            <polygon
              points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
              style={{ fill: "white" }}
            />
          </svg>
          <p>Back</p>
        </button>
        <h2>{nft.name}</h2>
        <h4>Creator: {nft.user_address}</h4>
        <div className="nftInfo__box">
          {/*<img src={nft.image?.fileUrl} alt="" />*/}

          <ModalImage nft={nft} imageSrc={nft.image?.fileUrl} altText="X Image" />

          <div className="nftInfo__box_right">
            <div className="nftInfo__box_right_top">
              <h4 className="nftInfo__box_right_top_h4">Contract</h4>
              <div className="nftInfo__box_right_top_content">
                <p className="nftInfo__box_right_top_content_p">
                  Category: {nft.categoryTitle ? nft.categoryTitle : "-"}
                </p>
                <p className="nftInfo__box_right_top_content_p">
                Collection: 
                <a href={`https://tribeton.io/collection/${collection._id}`}>
                 {collection.name ? collection.name : "-"}
                  </a>
                </p>
                <p
                  className="nftInfo__box_right_top_content_p"
                  style={{ wordWrap: "break-word" }}
                >
                  Description: {nft.description ? nft.description : "-"}
                </p>
              </div>
            </div>
            <div className="nftInfo__box_right_bottom">
              <p className="nftInfo__box_right_bottom_h4">Social networks:</p>
              <div className="nftInfo__box_right_top_content">
                <p className="nftInfo__box_right_top_content_p">Telegram: <a
                    target='_blank'
                    rel="noreferrer"
                    href={`https://t.me/${nft.telegram}`}>{nft.telegram ? nft.telegram : "-"}</a></p>
                <p className="nftInfo__box_right_top_content_p">YouTube: <a
                    target='_blank'
                    rel="noreferrer"
                    href={`https://www.youtube.com/${nft.youtube}`}>{nft.youtube ? nft.youtube : "-"}</a></p>
                <p className="nftInfo__box_right_top_content_p">Discord: <a
                    target='_blank'
                    rel="noreferrer"
                    href={`https://discord.gg/@${nft.discord}`}>{nft.discord ? nft.discord : "-"}</a>
                </p>
                <p className="nftInfo__box_right_top_content_p">X: <a
                    target='_blank'
                    rel="noreferrer"
                    href={`https://x.com/#{nft.x}`}>{nft.x ? nft.x : "-"}</a></p>
                <p className="nftInfo__box_right_top_content_p">Token ID: -</p>
                <p className="nftInfo__box_right_top_content_p">
                  Transaction Hash:
                </p>
                <p className="nftInfo__box_right_top_content_p">-</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
