import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { BASE_URL } from '../../services/baseURL';
function EditRewardModal({ open, reward, onClose, onSave, onChange, onNestedChange }) {
  const [dayCount, setDayCount] = useState(0);
  const [bannerPreview, setBannerPreview] = useState(null);

  useEffect(() => {
    if (reward && reward.startDate && reward.endDate) {
      const start = new Date(reward.startDate);
      const end = new Date(reward.endDate);
      const diffInMs = end - start;
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      setDayCount(days);
    } else {
      setDayCount(0);
    }

    // Устанавливаем превью, если у баннера есть URL-адрес (в случае обновления)
    if (reward?.banner && typeof reward.banner === 'string') {
      setBannerPreview(reward.banner);
    }
  }, [reward]);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange('banner', file); // Сохраняем файл в объекте reward
      setBannerPreview(URL.createObjectURL(file)); // Устанавливаем превью изображения
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        ...reward,
        coefficients: {
          nftLevel: reward?.coefficients?.nftLevel || 0,
          nftLikesCount: reward?.coefficients?.nftLikesCount || 0,
          userBoostsCount: reward?.coefficients?.userBoostsCount || 0,
        },
        banner: reward?.banner || null, // Добавляем баннер (URL или null)
      };
  
      const response = await fetch(`${BASE_URL}/rewards_season/${reward._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        onSave(); // Уведомляем об успешном сохранении
      } else {
        console.error('Failed to save changes:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ borderRadius: 3, boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)' }}>
      <DialogTitle sx={{ backgroundColor: '#1e3a8a', color: '#fff', fontWeight: 'bold', textAlign: 'center', py: 2 }}>
        Edit Reward
      </DialogTitle>
      <DialogContent sx={{ py: 4, px: 3, backgroundColor: '#f7f9fb' }}>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          variant="outlined"
          value={reward?.name || ''}
          onChange={(e) => onChange('name', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Description"
          fullWidth
          variant="outlined"
          value={reward?.description || ''}
          onChange={(e) => onChange('description', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Accept NFT Level"
          type="number"
          fullWidth
          variant="outlined"
          value={reward?.acceptNftLevel || 0}
          onChange={(e) => onChange('acceptNftLevel', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Total Rewards Amount"
          type="number"
          fullWidth
          variant="outlined"
          value={reward?.totalRewardsAmount || 0}
          onChange={(e) => onChange('totalRewardsAmount', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="Start Date"
          type="date"
          fullWidth
          variant="outlined"
          value={reward?.startDate || ''}
          onChange={(e) => onChange('startDate', e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="End Date"
          type="date"
          fullWidth
          variant="outlined"
          value={reward?.endDate || ''}
          onChange={(e) => onChange('endDate', e.target.value)}
          sx={{ mb: 2 }}
        />

        <Typography variant="body2" sx={{ mt: 2, color: '#1e3a8a' }}>
          Duration: {dayCount} {dayCount === 1 ? 'day' : 'days'}
        </Typography>

        <Divider sx={{ my: 3, borderColor: '#1e3a8a', borderWidth: '1px' }} />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1e3a8a', mb: 1 }}>
          Upload Banner
        </Typography>
        <input type="file" accept="image/*" onChange={handleBannerChange} />
        {bannerPreview ? (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <img src={bannerPreview} alt="Banner Preview" style={{ width: '90%', maxHeight: '240px', borderRadius: '8px' }} />
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
            Banner Preview will appear here
          </Typography>
        )}

        <FormControlLabel
          control={
            <Switch
              checked={reward?.isActive || false}
              onChange={(e) => onChange('isActive', e.target.checked)}
            />
          }
          label="Is Active"
          sx={{ my: 2, color: '#1e3a8a' }}
        />

        <Divider sx={{ my: 3, borderColor: '#1e3a8a', borderWidth: '1px' }} />

        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#1e3a8a', mb: 1 }}>
          Coefficients
        </Typography>
        <TextField
          margin="normal"
          label="NFT Level"
          type="number"
          fullWidth
          variant="outlined"
          value={reward?.coefficients?.nftLevel || 0}
          onChange={(e) => onNestedChange('coefficients', 'nftLevel', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="NFT Likes Count"
          type="number"
          fullWidth
          variant="outlined"
          value={reward?.coefficients?.nftLikesCount || 0}
          onChange={(e) => onNestedChange('coefficients', 'nftLikesCount', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="normal"
          label="User Boosts Count"
          type="number"
          fullWidth
          variant="outlined"
          value={reward?.coefficients?.userBoostsCount || 0}
          onChange={(e) => onNestedChange('coefficients', 'userBoostsCount', Number(e.target.value))}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', backgroundColor: '#f1f1f1', py: 2 }}>
        <Button onClick={onClose} color="secondary" variant="outlined" sx={{ mr: 2, borderColor: '#1e3a8a', color: '#1e3a8a' }}>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" sx={{ backgroundColor: '#1e3a8a', '&:hover': { backgroundColor: '#16336e' } }}>
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditRewardModal;
