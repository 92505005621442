import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./globalstyles.scss";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import LoginForm from "./Pages/LoginForm/LoginForm";
import Home from "./Pages/Home/Home";
import Category from "./Pages/Cartegories/Category";
import Moderation from "./Pages/Moderation/Moderation";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Gallery from "./Pages/Gallery/Gallery";
import Blog from "./Pages/Blog/Blog";
import "./utils/routerCustom";
import "./utils/preventDevTools";
import ProtectedRoute from "./utils/ProtectedRoute";
import PublicRoute from "./utils/PublicRoute";
import ManageWait from "./Pages/Manage/ManageWait/ManageWait";
import ManageActive from "./Pages/Manage/ManageActive/ManageActive";
import ManageAllNft from "./Pages/Manage/ManageAllNft/ManageActive";
import ChangeLikePrice from "./Pages/ChangePriceLike/ChangePriceLike";
import Seo from "./Pages/Seo/Seo";
import Rewards from "./Pages/Rewards/Rewards";
import UserBoost from "./Pages/UserBoost/UserBoost";
import Withdrawal from "./Pages/Withdrawal/Withdrawal";
import Point from "./Pages/Point/Point";
import AllNftPage from "./Pages/Manage/AllNft/AllNftPage.jsx";
function App() {
  return (
    <TonConnectUIProvider manifestUrl="https://bafybeiairsnianykvg5gdadfqxqhxzoba7kjme7dxbno7gn3na6g5jovpa.ipfs.w3s.link/tonconnect-manifest.json">
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route
              path="login"
              element={<PublicRoute element={<LoginForm />} />}
            />
            <Route
              path="home"
              element={<ProtectedRoute element={<Home />} />}
            />
            <Route
              path="category"
              element={<ProtectedRoute element={<Category />} />}
            />
            <Route
              path="moderation"
              element={<ProtectedRoute element={<Moderation />} />}
            />
            <Route
              path="gallery"
              element={<ProtectedRoute element={<Gallery />} />}
            />
            <Route
              path="blog"
              element={<ProtectedRoute element={<Blog />} />}
            />
            <Route path="*" element={<Navigate to="/home" replace />} />
            <Route
              path="manage-nft/active-items"
              element={<ProtectedRoute element={<ManageActive />} />}
            />
            <Route
              path="manage-nft/wait-to-publish"
              element={<ProtectedRoute element={<ManageWait />} />}
            />
            <Route
              path="manage-nft/all-nft"
              element={<ProtectedRoute element={<ManageAllNft />} />}
            />
            <Route
              path="manage-nft/all"
              element={<ProtectedRoute element={<AllNftPage />} />}
            />
            <Route path="seo" element={<ProtectedRoute element={<Seo />} />} />
            <Route
              path="chagePriceLike"
              element={<ProtectedRoute element={<ChangeLikePrice />} />}
            />
            <Route
              path="rewards"
              element={<ProtectedRoute element={<Rewards />} />}
            />
            <Route
              path="boost-user"
              element={<ProtectedRoute element={<UserBoost />} />}
            />
            <Route
              path="withdrawal"
              element={<ProtectedRoute element={<Withdrawal />} />}
            />
            <Route
              path="point"
              element={<ProtectedRoute element={<Point />} />}
            />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
